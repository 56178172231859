.btn-social-media {
  position: absolute;
  top: 60px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 160px;
  z-index: 999;
}

.btn-social-media button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  border-radius: 1.5rem !important;
  transition: 1s !important;
}

.btn-social-media button > strong {
  display: none;
}

.btn-social-media button:hover > strong {
  display: inline-block;
  font-size: 1rem;
}

.btn-social-media button:hover {
  width: 150px;
  border-radius: 1rem !important;
  font-size: 1rem;
  margin: 0 0.3rem;
}

.btn-facebook {
  color: var(--white) !important;
  background: linear-gradient(87deg, #28087e 0, #3b5999 100%) !important;
  border-color: #9eb5e9 !important;
}
.btn-facebook:hover {
  background-color: #043191 !important;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  background-color: #546b9b !important;
}

.btn-instagram {
  color: var(--white) !important;
  background: linear-gradient(
    60deg,
    #f4931b 10%,
    #d83452 30%,
    #533f9d 100%
  ) !important;
  background: -webkit-linear-gradient
    (60deg, #f4931b 10%, #d83452 30%, #533f9d 100%) !important;
  border-color: #ebb3be !important;
}
.btn-instagram:hover {
  background-color: #aa102c !important;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  background-color: #d83452 !important;
}

.btn-whatsapp {
  color: var(--white) !important;
  background: linear-gradient(87deg, #0cb12a 10%, #25d366 100%) !important;
  background-color: #25d366 !important;
  border-color: #abebb7 !important;
}
.btn-whatsapp:hover {
  background-color: #0cb12a !important;
}
.btn-whatsapp:not(:disabled):not(.disabled):active,
.btn-whatsapp:not(:disabled):not(.disabled).active,
.show > .btn-whatsapp.dropdown-toggle {
  background-color: #3df55f !important;
}

@media (max-width: 576px) {
  .btn-social-media {
    flex-direction: column;
    align-items: flex-end;
    min-height: 140px;
    min-width: 2.5rem;
  }
  .btn-social-media button {
    width: 2.5rem;
    height: 2.5rem;
    padding: 8px !important;
  }
}
